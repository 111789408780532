<template>
  <div>
    <div id="nav">
      <b-navbar toggleable="sm" variant="faded" type="light">
        <b-navbar-brand>
          <a
            v-if="this.$i18n.locale == 'en'"
            href="https://navigatingwhales.ca/en/home/"
            target="_blank"
          >
            <img
              :src="`../assets/images/general/logo-fr.png`"
              class="d-inline-block align-top"
              alt="ROMM logo"
              id="logo"
            />
          </a>
          <a
            v-if="this.$i18n.locale == 'fr'"
            href="https://navigationbaleines.ca/fr/accueil/"
            target="_blank"
          >
            <img
              :src="`../assets/images/general/logo-fr.png`"
              class="d-inline-block align-top"
              alt="ROMM logo"
              id="logo"
            />
          </a>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-if="$store.state.pwa.isOnline">
            <b-nav-item
              class="nav-item-font"
              href="https://www.visualisation.navigationbaleines.ca/"
              v-if="$i18n.locale === 'fr'"
              >{{ $t("viz-data") }}</b-nav-item
            >
            <b-nav-item
              class="nav-item-font"
              href="https://navigationbaleines.ca/fr/signaler-une-baleine-en-difficulte/"
              v-if="$i18n.locale === 'fr'"
              >{{ $t("report-whale") }}</b-nav-item
            >
            <b-nav-item
              class="nav-item-font"
              href="https://visualization.navigatingwhales.ca/"
              v-if="$i18n.locale === 'en'"
              >{{ $t("viz-data") }}</b-nav-item
            >
            <b-nav-item
              class="nav-item-font"
              href="https://navigatingwhales.ca/en/reporting-a-marine-mammal-in-difficulty/"
              v-if="$i18n.locale === 'en'"
              >{{ $t("report-whale") }}</b-nav-item
            >
            <b-nav-item
              class="nav-item-font"
              @click="switchLocale(locale)"
              v-for="locale in locales"
              :key="locale"
              :class="locale == $i18n.locale ? 'hide' : ''"
              >{{ locale }}</b-nav-item
            >
            <b-nav-item-dropdown id="dropdownUser" right>
              <template slot="button-content">
                <b-icon-person-x-fill
                  v-if="!ogslUserData"
                  style="color: #dcede9"
                ></b-icon-person-x-fill>
                <b-icon-person-check-fill
                  v-if="ogslUserData"
                  style="color: #dcede9"
                ></b-icon-person-check-fill>
              </template>
              <b-dropdown-item
                id="dropdownLoginItem"
                v-if="!ogslUserData"
                @click="handleLoginClick"
                >{{ $t("log-in") }}</b-dropdown-item
              >
              <b-dropdown-item
                id="dropdownUserNameItem"
                v-if="ogslUserData"
                disabled
                >{{ ogslUserData.name }}</b-dropdown-item
              >
              <b-dropdown-item
                id="dropdownUserEmailItem"
                v-if="ogslUserData"
                disabled
                >{{ ogslUserData.email }}</b-dropdown-item
              >
              <b-dropdown-item
                id="dropdownDownloadlItem"
                v-b-modal.download-report
                v-if="ogslUserData && groupMemberships == true"
                >Télécharger rapport</b-dropdown-item
              >
              <b-modal
                id="download-report"
                centered
                title="Télécharger rapport"
                ok-title="Oui"
                cancel-title="Non"
                @ok="downloadReport()"
              >
                <p class="my-4">
                  Voulez-vous vraiment télécharger le rapport complet des
                  observations ?
                </p>
                <p class="my-4">
                  Si oui, un émail vous sera envoyer dans quelques minutes
                </p>
              </b-modal>
              <b-dropdown-item
                id="dropdownLogoutItem"
                v-if="ogslUserData"
                @click="handleLogoutClick"
                >{{ $t("log-out") }}</b-dropdown-item
              >
              <b-dropdown-item
                id="dropdownChangelogItem"
                @click="$router.push('/changelog')"
                >{{ $t("changelog") }}</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav v-else>
            <b-nav-item
              class="nav-item-font"
              style="
                border-color: #f3e31d;
                border-style: solid;
                border-width: medium;
              "
              >Aucune connexion détectée. Mode offline activé</b-nav-item
            >
            <b-nav-item
              class="nav-item-font"
              @click="switchLocale(locale)"
              v-for="locale in locales"
              :key="locale"
              :class="locale == $i18n.locale ? 'hide' : ''"
              >{{ locale }}</b-nav-item
            ></b-navbar-nav
          >
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import * as ogslAuth from "@/commons/ogsl-auth.js";
import axios from "axios";
import { getCookie } from "tiny-cookie";
export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: "Header",
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    };
  },
  // mounted () {
  //   ogslAuth.eventEmitter.addListener(
  //     ogslAuth.EVENT_USER_DATA_CHANGED,
  //     (data) => {
  //       this.$store.commit('setOgslUserData', data)
  //       this.effortClosed()
  //     }
  //   )
  //   ogslAuth.getUserData()
  // },
  // destroyed () {
  //   ogslAuth.eventEmitter.removeAllListeners(ogslAuth.EVENT_USER_DATA_CHANGED)
  // },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
    handleLoginClick() {
      ogslAuth.openLoginWindow(this.$i18n.locale);
    },
    async handleLogoutClick() {
      await ogslAuth.logout();

      if (this.$route.path != "/") await this.$router.push("/");

      this.$bvToast.toast(this.$t("disconnected"), {
        title: this.$t("success"),
        variant: "success",
        autoHideDelay: 5000,
        solid: true,
      });
    },
    async downloadReport() {
      this.$store.commit("isSendObservationInProgress", true);
      var sessionIdData = getCookie("sessionId");
      try {
        await axios({
          url: "/api/report",
          method: "post",
          baseURL: process.env.VUE_APP_OUTIL_SAISIE_BASE_URL,
          headers: {
            "X-API-KEY": sessionIdData,
          },
        });

        this.$store.commit("isSendObservationInProgress", false);
        this.$bvToast.toast(this.$t("downloadReportSuccess"), {
          title: this.$t("success"),
          variant: "success",
          solid: true,
        });
      } catch (err) {
        this.$store.commit("isSendObservationInProgress", false);
        console.log(err);
        this.$bvToast.toast(this.$t("errorDownloadReport"), {
          title: this.$t("error"),
          variant: "danger",
          solid: true,
        });
      }
    },
    // effortClosed () {
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: '/api/eventDTOs/getEventDTOFromFilters',
    //       method: 'get',
    //       baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
    //       contentType: 'application/json; charset=utf-8',
    //       headers: {
    //         'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
    //         'X-AUTH-TOKEN': getCookie('sessionId')
    //       },
    //       params: {
    //         currentuser: true,
    //         parentonly: true,
    //         hasoccurrence: false,
    //         max: 5,
    //         offset: 0
    //       }
    //     }).then(response => {
    //       console.log(response)
    //       response.data.forEach(element => {
    //         const extradataData = JSON.parse(element.extradata.data)
    //         if (extradataData.effortclosed === false) {
    //           this.$store.commit('idEffort', element.id)
    //           this.$store.commit('hubIdEffort', element.hubId)
    //           this.$store.commit('endEffort', false)
    //           this.$store.commit('partnerEffort', element.organizationCode)
    //           this.$store.commit('shipEffort', extradataData.ship)
    //           this.$store.commit('mmsiEffort', extradataData.mmsi)
    //           console.log(element.id)
    //           console.log('Ya un effort pas finis')
    //           console.log(element)
    //         }
    //       })
    //       resolve(response)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // }
  },
  computed: {
    ogslUserData() {
      return this.$store.getters.ogslUserData;
    },
    groupMemberships() {
      var groupMembershipsData = false;
      if (this.ogslUserData) {
        var group = this.ogslUserData.groupMemberships;
        if ((group & 512) === 512) {
          groupMembershipsData = true;
        }
      }

      return groupMembershipsData;
    },
  },
};
</script>

<style scoped>
#nav {
  flex-shrink: 0;
  background-color: #0a1d31;
}

#logo {
  height: 55px;
}

.navbar-nav > .nav-item > .nav-link {
  color: #dcede9 !important;
  text-transform: uppercase;
}

.navbar-toggler {
  background-color: #dcede9 !important;
}

.nav-link {
  display: inline-block !important;
}

.btn,
.nav-item-font {
  font-family: "geomanistmedium" !important;
  text-transform: uppercase;
}
.hide {
  display: none;
}
</style>
<style>
.navbar-nav {
  width: 100%;
}

#dropdownUser {
  margin-left: auto;
}

#dropdownUser a {
  color: #dcede9;
}

#dropdownUser ul {
  background-color: #0a1d31;
  border: 1px solid #dcede9;
}

#dropdownUserNameItem {
  font-weight: bold;
}

#dropdownUserEmailItem {
  font-size: 0.8375rem;
}

#dropdownUserSummaryItem {
  border-bottom: 1px solid rgb(220, 237, 233, 0.5);
  padding-bottom: 0.5rem;
}

#dropdownLoginItem:hover,
#dropdownLogoutItem:hover,
#dropdownUserSummaryItem:hover,
#dropdownDownloadlItem:hover,
#dropdownChangelogItem:hover {
  background-color: #dcede9 !important;
  color: #0a1d31 !important;
}

@media screen and (max-width: 576px) {
  #dropdownUser {
    margin-left: 0;
  }
}
</style>
